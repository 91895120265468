// 3rd-party modules
import { Slider, Tooltip, message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

// project modules
import AccountBlockedSites from '../blocked-sites/AccountBlockedSites';
import AccountInternetSchedules from '../internet-schedules/AccountInternetSchedules';
import AccountKiboshDeviceModal from '../kibosh-device/AccountKiboshDeviceModal';
import Button from '../../shared/button';
import Checkbox from '../../shared/inputs/checkbox';
// import ConfirmationPopup from '../../shared/popup/confirmationPopup';
import DataTable, { TableColumn } from '../../shared/list/dataTable';
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import TabStrip from '../../shared/tabstrip';
import Select from '../../shared/inputs/select';
import Tab from '../../shared/tabstrip/tab';
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";
import { getDeviceSignalRateClassIcon } from '../../../helpers';
import { getStringFirstLetters } from "../../../helpers/objectHelper";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import AccountDeviceBlockingModal from '../devices/AccountDeviceBlockingModal';
import { Account } from '../../../models/account';
import { AccountDeviceGroup } from '../../../models/accountDeviceGroup';
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import { ApiResponse } from "../../../models/response";
import { accountDeviceGroupUpdateViewModel } from '../../../models/types/accountDeviceGroup';
import { accountDeviceBlockViewModel } from '../../../models/types/accountDevice';
import { AccountDevice } from '../../../models/accountDevice';
import { CONSTANTS } from '../../../helpers/defines';
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../../models/dataSourceRequest';
import { ListViewType } from '../../shared/list/useListHook';
import moment from 'moment-timezone';
import ListView from '../../shared/list/listView';
import AccountDeviceMenuModal from '../devices/AccountDeviceMenuModal';
import AccountDeviceActivityLogs from '../device-activity-logs/AccountDeviceActivityLogs';
import AccountDeviceBandwidthLogs from '../device-bandwidth-logs/AccountDeviceBandwidthLogs';
import TimerComponent from '../../shared/timer';
import ConfirmationPopup from '../../shared/popup/confirmationPopup';
// import Scroll from '../../shared/scroll';
import { SvgDoodle } from '../../svg';

type Props = {
  account: Account;
  accountDeviceGroup: AccountDeviceGroup;
  accountDeviceGroups?: AccountDeviceGroup[];
  // accountKiboshDevice: AccountKiboshDevice;
  accountKiboshDevice: AccountKiboshDevice;
  accountVpns?: any[];
  closeOnSave?: boolean;
  modalTitle?: string;
  preSelectedTab?: string;
  open: boolean;
  showTabs?: boolean;
  showGroupEditSection?: boolean;
  onClose?: () => void;
  onMembersListChange?: () => void;
  onSave?: (accountDeviceGroup: any) => void;
};

// const getDeviceTypes = async (abortSignal?: AbortSignal) => {
//   const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

//   return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
// };

export default function AccountDeviceGroupModal({ account, accountDeviceGroup, accountKiboshDevice, accountVpns = [], accountDeviceGroups = [], preSelectedTab, closeOnSave = false, modalTitle = "", open, showTabs = true, showGroupEditSection = true, onClose, onMembersListChange, onSave }: Props) {
  const columns: TableColumn<AccountDevice>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      filterable: true,
      width: 150
    },
    {
      title: 'Device Name',
      dataIndex: 'name',
      key: 'name',
      filterable: true,
    },
    {
      title: 'Device IP',
      dataIndex: 'ip',
      key: 'ip',
      filterable: true,
      width: 110
    },
    {
      title: 'Static IP',
      dataIndex: 'staticIp',
      key: 'staticIp',
      filterable: true,
      width: 110
    },
    // {
    //   title: 'WiFi Signal',
    //   dataIndex: 'wifiSignal',
    //   key: 'wifiSignal',
    //   dataType: 'number',
    //   width: 90
    // },
    // {
    //   title: 'Ping',
    //   dataIndex: 'pingMs',
    //   key: 'pingMs',
    //   filterable: true,
    //   width: 100,
    //   render: (text: number) => text > 0 ? `${text.toFixed(2)}ms` : "disconnected"
    // },
    {
      title: 'Quality',
      key: 'connectionQuality',
      width: 100,
      render: (_, record: any) => (
        <group data-justify='center'>
          <icon>{getDeviceSignalRateClassIcon(record.pingMs!)}</icon>
        </group>
      )
    },
    {
      title: 'Connection',
      dataIndex: 'connectionStatus',
      key: 'connectionStatus',
      width: 110
    },
    {
      title: 'Activity Logs',
      dataIndex: 'loggingEnabled',
      key: 'loggingEnabled',
      dataType: 'bool',
      width: 110
    },
    {
      title: '',
      key: 'action',
      width: 30,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditDeviceClick(record)}><icon>edit_square</icon></div></Tooltip>
          <separator vertical="" data-adaptive="desktop"></separator>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onRemoveClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const schema = yup.object().shape({
    name: yup.string().label("Device Name").max(256).required()
  });
  const [activityLogsEnabled, setActivityLogsEnabled] = useState<boolean>(false);
  const [currentAccountDeviceId, setCurrentAccountDeviceId] = useState<string>("");
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup>(accountDeviceGroup);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [freeAccountDevices, setFreeAccountDevices] = useState<AccountDevice[]>([]);
  // const [blockingData, setBlockingDate] = useState<accountDeviceBlockViewModel | null>(null);
  const [internetData, setInternetData] = useState<accountDeviceBlockViewModel | null>(null);
  // const [isBlockingConfirmationPopupOpen, setBlockingConfirmationPopupOpen] = useState(false);
  const [isHasInternetConfirmationPopupOpen, setHasInternetConfirmationPopupOpen] = useState(false);
  const [showAccountDeviceMenuModal, setShowAccountDeviceMenuModal] = useState(false);
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice>(new AccountDevice());
  // const [isUnblocked, setIsUnblocked] = useState<boolean>(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);

  const [hasInternet, setHasInternet] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [internetPauseRemainingTime, setInternetPauseRemainingTime] = useState<number>(0);
  const [internetPauseTotalTime, setInternetPauseTotalTime] = useState<number>(0);
  const [dataSource, setDataSource] = useState<AccountDevice[]>([]);
  const [minutesBeforeExpiration, setMinutesBeforeExpiration] = useState<number>(0);
  const [hoursBeforeExpiration, setHoursBeforeExpiration] = useState<number>(0);

  const abortController = new AbortController();

  const { control, handleSubmit } = useForm<accountDeviceGroupUpdateViewModel | any>({
    defaultValues: { ...currentAccountDeviceGroup },
    resolver: yupResolver(schema),
  });

  const getPageAsync = async () => {
    setDataSource(await getPage())
  }

  useEffect(() => {
    if (open) {
      if (!showGroupEditSection) {
        getPageAsync();
      }
      if (preSelectedTab) {
        switch (preSelectedTab) {
          case 'details':
            setCurrentTab(0)
            break;
          case 'vpns':
            setCurrentTab(1)
            break;
          case 'applications':
            setCurrentTab(accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ? 2 : 1)
            break;
          case 'blocked-sites':
            setCurrentTab(accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ? 3 : 2)
            break;
          case 'internet-schedules':
            setCurrentTab(accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ? 4 : 3)
            break;
          case 'internet-pause':
            setCurrentTab(accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ? 5 : 4)
            break;
          case 'history':
            setCurrentTab(accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ? 6 : 5)
            break;
          case 'bandwidth':
            setCurrentTab(accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ? 7 : 6)
            break;
        }
      }
    }
  }, [open]);

  useEffect(() => {
    setHasInternet(!currentAccountDeviceGroup.isBlocked);
    setActivityLogsEnabled(!!currentAccountDeviceGroup.loggingEnabled);
    // setIsUnblocked(!currentAccountDeviceGroup.isBlocked);

    if (currentAccountDeviceGroup.isBlocked) {
      const remainingTime = getTimeRemaining();
      setInternetPauseRemainingTime(Math.floor(remainingTime.total));
      const totalTime = getTimeTotal();
      setInternetPauseTotalTime(Math.floor(totalTime.total));
    }
  }, [currentAccountDeviceGroup]);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (reload) {
      if (!showGroupEditSection) {
        getPageAsync();
      }
      setReload(false);
    }
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));

    const data = response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
    const freeDevices = data.filter(x => !x.groupId);
    const groupDevices = data.filter(x => x.groupId === currentAccountDeviceGroup.id);

    setFreeAccountDevices(freeDevices);

    setTotalRecords(groupDevices.length);

    setLoading(false);

    return groupDevices;
  };

  const onAccountDeviceSave = () => {
    setReload(true);
  }

  const onAddNewDeviceToGroup = async () => {
    setLoading(true);
    const data: accountDeviceGroupUpdateViewModel = {
      name: currentAccountDeviceGroup.name!,
      kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
      icon: currentAccountDeviceGroup.icon
    }
    let response: ApiResponse = await apiCall(DashApi.addDeviceToDeviceGroup(currentAccountDeviceGroup.id!, currentAccountDeviceId, data, abortController.signal));

    if (response.success) {
      message.success(`Device added successfully.`);
      setCurrentAccountDeviceId("");
      setReload(true);

      if (onMembersListChange) onMembersListChange();
    } else
      message.error(response.error?.value);

    setLoading(false);
  }

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onEditDeviceClick = async (record: any) => {
    setCurrentAccountDevice(record);
    setShowAccountDeviceMenuModal(true);
  };

  // const onRemoveClick = async (record: any) => {
  //   setLoading(true);
  //   let response: ApiResponse = await apiCall(DashApi.removeDeviceFromDeviceGroup(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, record.id, abortController.signal));

  //   if (response.success) {
  //     message.success(`Device removed successfully.`);
  //     setCurrentAccountDeviceId("");
  //     setReload(true);

  //     if (onMembersListChange) onMembersListChange();
  //   } else
  //     message.error(response.error?.value);

  //   setLoading(false);
  // };



  const onRemoveClick = (record: any) => {
    setCurrentAccountDevice(record);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    let response: ApiResponse = await apiCall(DashApi.removeDeviceFromDeviceGroup(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, currentAccountDevice.id!, abortController.signal));

    if (response.success) {
      message.success(`Device removed successfully.`);
      setCurrentAccountDeviceId("");
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);

      if (onMembersListChange) onMembersListChange();
    }
    else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onSubmit: SubmitHandler<accountDeviceGroupUpdateViewModel> = async (formData: accountDeviceGroupUpdateViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = accountKiboshDevice.kiboshDeviceReference!;

    if (!currentAccountDeviceGroup?.id)
      response = await apiCall(DashApi.insertDeviceGroup(formData, undefined, abortController.signal));
    else
      response = await apiCall(DashApi.updateDeviceGroup(currentAccountDeviceGroup.id, formData, abortController.signal));

    if (response.success) {
      message.success(`Profile ${!currentAccountDeviceGroup?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(response.data?.value);

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onActivityLogsCheckboxChange = async (event: any) => {
    setLoading(true);

    const response = await apiCall(DashApi.updateDeviceActivityLogEnableState(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, event.target?.checked, abortController.signal));

    if (response.success) {
      message.success("Activity Logs enabled state changed successfully.");
      setActivityLogsEnabled(!event.target?.checked);

      if (onSave) onSave(response.data?.value);
    } else
      message.error(response.error?.value);

    setLoading(false);
  }

  const onInternetPauseClick = async () => {
    const data: accountDeviceBlockViewModel = {
      blocked: hasInternet,
      deviceId: `${currentAccountDeviceGroup.id!}`,
      kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
      // minutesBeforeExpiration:  0
      minutesBeforeExpiration: hasInternet ? (hoursBeforeExpiration * 60) + minutesBeforeExpiration : 0
    }
    setInternetData(data);
    setHasInternetConfirmationPopupOpen(true);
  }

  const onInternetCheckboxChange = async (event: any) => {
    const data: accountDeviceBlockViewModel = {
      blocked: !event.target?.checked,
      deviceId: `${currentAccountDeviceGroup.id!}`,
      kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
      minutesBeforeExpiration: 0
    }
    setInternetData(data);
    setHasInternetConfirmationPopupOpen(true);
  }

  const getAccountDeviceGroup = async () => {
    if (!accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id) {
      return;
    }

    setLoading(true);
    const response = await apiCall(DashApi.getDeviceGroupById(accountKiboshDevice.kiboshDeviceReference!, currentAccountDeviceGroup.id!, false, abortController.signal));
    setLoading(false);

    return response.success ? AccountDeviceGroup.toClass(response.data?.value) : currentAccountDeviceGroup;
  };

  const onAccountDeviceBlockingSave = async () => {
    setHasInternet(!hasInternet)
    const data = await getAccountDeviceGroup() || currentAccountDeviceGroup;
    setCurrentAccountDeviceGroup(data);
    if (onSave) {
      onSave(data);
    }
  }

  const getTimeRemaining = () => {
    const now = moment(); //todays date
    const end = moment(currentAccountDeviceGroup.blockedUntil); // another date
    const duration = moment.duration(end.diff(now));
    const total = duration.asSeconds();
    const seconds = Math.floor(
      total % 60
    );
    const minutes = Math.floor(
      (total / 60) % 60
    );
    const hours = Math.floor(
      (total / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const getTimeTotal = () => {
    const start = moment(currentAccountDeviceGroup.blockedAt); //begining date
    const end = moment(currentAccountDeviceGroup.blockedUntil); // another date
    const duration = moment.duration(end.diff(start));
    const total = duration.asSeconds();
    const seconds = Math.floor(
      total % 60
    );
    const minutes = Math.floor(
      (total / 60) % 60
    );
    const hours = Math.floor(
      (total / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const getClientIcon = (clientType: any): string => {
    let icon = "install_desktop"
    switch (clientType) {
      case "laptop":
        icon = "laptop_windows"
        break;
      case "desktop":
        icon = "computer"
        break;
      case "mobile":
        icon = "smartphone"
        break;
      case "tablet":
        icon = "tablet"
        break;
      case "security-camera":
        icon = "speed_camera"
        break;
      case "thermostat":
        icon = "thermostat"
        break;
      case "doorbell":
        icon = "doorbell"
        break;
      case "lighting":
        icon = "wb_incandescent"
        break;
      case "tv":
        icon = "tv"
        break;
      case "voip-phone":
        icon = "call"
        break;
      case "servers":
        icon = "dns"
        break;
      case "others":
        icon = "devices"
        break;
    }
    return icon;
  }

  // Format time remaining to MM:SS format
  const formatedTime = (): string => {
    return `${hoursBeforeExpiration}:${minutesBeforeExpiration < 10 ? '0' : ''}${minutesBeforeExpiration}`;
  };

  const renderDetailsTab = () => {
    return (
      <>
        <view>
          <view className="table_cont" data-background="none">
            <group
              data-space="15"
              data-gap="20"
              data-border=""
              data-background="highlight"
            >
              <Input
                control={control}
                labelPosition="left"
                name="name"
                label="Profile Name"
                dataLength="400"
                size="large"
                button={
                  <Button
                    material
                    text="Save Changes"
                    primary
                    onClick={handleSubmit(onSubmit)}
                  />
                }
              />
              <Checkbox
                label="Activity Logs Enabled"
                minimal
                dataLength="auto"
                onChange={onActivityLogsCheckboxChange}
                checked={activityLogsEnabled} />
              <Checkbox
                label={hasInternet ? "Internet Enabled for device" : `Internet Disabled for device until ${moment(currentAccountDeviceGroup.blockedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
                minimal
                dataLength="auto"
                onChange={onInternetCheckboxChange}
                checked={hasInternet} />
            </group>
            <group
              data-space="15"
              data-gap="20"
              data-border=""
              data-background="highlight"
            >
              <Select
                autoComplete=""
                label="Device"
                labelPosition="left"
                dataLength="320"
                value={currentAccountDeviceId}
                onChange={(e: any) => setCurrentAccountDeviceId(e)}
                loading={loading}
                allowSearch={true}
                options={
                  freeAccountDevices?.map((item) => {
                    return {
                      text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                      value: item.id!,
                    };
                  }) || []
                }
              />
              <Button
                material
                disabled={!currentAccountDeviceId ? true : undefined}
                icon="add"
                text="Add Device to Profile"
                primary
                onClick={onAddNewDeviceToGroup}
              />
              <Button
                data-position="right"
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={() => setReload(true)}
              />
            </group>
            <DataTable
              columns={columns}
              bordered={true}
              customPagination={true}
              reload={reload}
              dataPagination={true}
              getPageAsync={getPage}
              loading={loading}
              rowKey={(record) => `${record.id}`}
              showTotal={true}
              size="small"
              totalRecords={totalRecords}
            />
          </view>
        </view>
      </>
    );
  }

  const BlockItem: React.FC<{ data: any }> = ({ data }) => {
    return (
      <group data-direction="column" data-border="" data-radius="10" data-contain="" data-background="highlight">
        <group
          data-direction="column"
          // data-gap="10"
          data-space="5"
          data-space-horizontal="10"
          data-adaptive="400"
        >
          <group data-gap="5" data-width="auto" data-align="center" data-justify="start" data-space-horizontal="5">
            <icon data-color={data.connectionStatus === "offline" ? "" : "main"} data-opacity={data.connectionStatus === "offline" ? "50" : undefined} data-icon-size="48">{getClientIcon(data.deviceType)}</icon>
          </group>
          <group data-length="autofit">
            <group data-adaptive="500">
              <group data-direction="column" data-fit="1" data-space="10" data-gap="5">
                <text data-weight="700" data-color="main" data-wrap="wrap">{data.name}</text>
                <text data-wrap="no" data-ellipsis="">
                  Device IP: {!!data.staticIp ? '-' : data.ip}
                </text>
                <text data-wrap="no" data-ellipsis="">
                  Static IP: {data.staticIp || '-'}
                </text>
              </group>
              {/* <group data-direction="column" data-fit="1" data-space="10" data-gap="5">
                <group data-gap="5" data-align="center" data-wrap="no">
                  <text data-ellipsis="">Conn:</text>
                  <text data-color="deep-orange" data-ellipsis="">{data.connectionStatus}</text>
                </group>
                <group data-gap="5" data-align="center" data-wrap="no">
                  <text data-ellipsis="">Logs:</text>
                  <badge data-outline="">
                    <text data-ellipsis="">
                      {data.loggingEnabled ? "ON" : "OFF"}
                    </text>
                  </badge>
                </group>
              </group> */}
            </group>
            <group>
              <group
                data-align="center"
                //data-wrap="no"
                data-position="left"
                data-width="auto"
                data-space="5"
                data-gap="5"
              >
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <icon data-opacity={data.isBlocked ? "" : "50"} data-space="5" data-color={data.isBlocked ? "main-dark" : ""}>pause_circle</icon>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <icon data-opacity={!!data.numSetFiltersWithSchedule ? "" : "50"} data-space="5" data-color={!!data.numSetFiltersWithSchedule ? "main-dark" : ""}>schedule</icon>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <icon data-color="main" data-background="main-background" data-radius="3" data-space="5" >{getDeviceSignalRateClassIcon(data.pingMs!)}</icon>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <text data-opacity={!!data.loggingEnabled ? "" : "50"} data-space="5" data-color={!!data.loggingEnabled ? "main-dark" : ""}>Logs</text>
                </group>
                <group data-width="auto" data-background="main-background" data-radius="3">
                  <text data-opacity={data.connectionStatus !== "offline" ? "" : "50"} data-space="5" data-color={data.connectionStatus !== "offline" ? "main-dark" : ""}>{data.connectionStatus}</text>
                </group>
              </group>
              <group
                data-align="center"
                //data-wrap="no"
                data-position="right"
                data-width="auto"
                data-space="5"
              >
                <Tooltip title="Edit">
                  <Button
                    micro
                    icon="edit_square"
                    data-color="grey"
                    onClick={() => onEditDeviceClick(data)} />
                </Tooltip>
                <separator vertical="" data-adaptive="desktop"></separator>
                <Tooltip title="Remove device from this Profile">
                  <Button
                    micro
                    icon="delete"
                    data-color="grey"
                    onClick={() => onRemoveClick(data)} />
                </Tooltip>
              </group>
            </group>
          </group>

        </group>

      </group>
      // <group data-direction="column" data-border="" data-radius="10" data-contain="" data-background="highlight">
      //   <group
      //     data-gap="10"
      //     data-space="5"
      //     data-space-horizontal="10"
      //   //data-adaptive="400"
      //   >

      //     <group data-length="autofit">

      //       <group data-direction="column" data-fit="1" data-space="10" data-gap="5">
      //         <group data-gap="5" data-width="auto" data-align="center" data-justify="start">
      //           <icon data-color={data.connectionStatus === "offline" ? "" : "main"} data-opacity={data.connectionStatus === "offline" ? "50" : undefined} data-icon-size="mini">{getClientIcon(data.deviceType)}</icon>
      //         </group>
      //         <text data-weight="700" data-color="main" data-wrap="wrap">{data.name}</text>
      //         <text data-wrap="no" data-ellipsis="">
      //           Data IP: {data.ip}
      //         </text>
      //         <text data-wrap="no" data-ellipsis="">
      //           Static IP: {data.staticIp || '-'}
      //         </text>
      //       </group>
      //       {/* <group data-direction="column" data-fit="1" data-space="10" data-gap="5">
      //           <group data-gap="5" data-align="center" data-wrap="no">
      //             <text data-ellipsis="">Conn:</text>
      //             <text data-color="deep-orange" data-ellipsis="">{data.connectionStatus}</text>
      //           </group>
      //           <group data-gap="5" data-align="center" data-wrap="no">
      //             <text data-ellipsis="">Logs:</text>
      //             <badge data-outline="">
      //               <text data-ellipsis="">
      //                 {data.loggingEnabled ? "ON" : "OFF"}
      //               </text>
      //             </badge>
      //           </group>
      //         </group> */}

      //       <group>
      //         <group
      //           data-align="center"
      //           //data-wrap="no"
      //           data-position="left"
      //           data-width="auto"
      //           data-space="5"
      //           data-gap="5"
      //         >
      //           <group data-width="auto" data-background="main-background" data-radius="3">
      //             <icon data-opacity="50" data-space="5" data-color={data.isBlocked ? "main-dark" : ""}>pause_circle</icon>
      //           </group>
      //           <group data-width="auto" data-background="main-background" data-radius="3">
      //             <icon data-opacity="50" data-space="5" data-color={!!data.numSchedules ? "main-dark" : ""}>schedule</icon>
      //           </group>
      //           <group data-width="auto" data-background="main-background" data-radius="3">
      //             <icon data-color="main" data-background="main-background" data-radius="3" data-space="5" >{getDeviceSignalRateClassIcon(data.pingMs!)}</icon>
      //           </group>
      //           <group data-width="auto" data-background="main-background" data-radius="3">
      //             <text data-opacity="50" data-space="5" data-color={!!data.loggingEnabled ? "main-dark" : ""}>Logs</text>
      //           </group>
      //           <group data-width="auto" data-background="main-background" data-radius="3">
      //             <text data-opacity="50" data-space="5" data-color={data.connectionStatus !== "offline" ? "main-dark" : ""}>{data.connectionStatus}</text>
      //           </group>

      //         </group>
      //         <group
      //           data-align="center"
      //           //data-wrap="no"
      //           data-position="right"
      //           data-width="auto"
      //           data-space="5"
      //         >
      //           <Tooltip title="Edit">
      //             <Button
      //               micro
      //               icon="edit_square"
      //               data-color="grey"
      //               onClick={() => onEditDeviceClick(data)} />
      //           </Tooltip>
      //           <separator vertical="" data-adaptive="desktop"></separator>
      //           <Tooltip title="Remove device from this Profile">
      //             <Button
      //               micro
      //               icon="delete"
      //               data-color="grey"
      //               onClick={() => onRemoveClick(data)} />
      //           </Tooltip>
      //         </group>
      //       </group>
      //     </group>

      //   </group>

      // </group>
    )
  };

  const renderDevicesTab = () => {
    return (
      <view
        data-scroll=""
        data-space="20"
        data-gap="30">
        <group data-gap="10" data-direction="column">
          <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Devices</text>
          <text data-wrap="wrap" data-length="300" data-line="1.5" data-light="">Here you can manage all your profile devices.</text>
        </group>

        <group data-align="center" data-gap="10">
          <group data-width="auto" data-align="center" data-gap="10" data-space="10" data-radius="5" data-elevation="1" data-backdrop="" data-length="forcefit" data-max-length="600" data-fit="1">
            <Select
              autoComplete=""
              label="Device"
              labelPosition="left"
              //dataLength="320"
              dataLength="forcefit"
              dataMinLength='200'
              dataFit="1"
              value={currentAccountDeviceId}
              onChange={(e: any) => setCurrentAccountDeviceId(e)}
              loading={loading}
              allowSearch={true}
              options={
                freeAccountDevices?.map((item) => {
                  return {
                    text: `${item.name!} ${item.ip ? '(' + item.ip + ')' : ''}`,
                    value: item.id!,
                  };
                }) || []
              }
            />

            <group data-width="auto" data-length="forcefit">
              <Button
                data-length="forcefit"
                secondary
                disabled={!currentAccountDeviceId ? true : undefined}
                icon="add"
                text="Add Device to Profile"
                onClick={onAddNewDeviceToGroup}
              />
            </group>

          </group>
          <separator data-vertical="" data-adaptive="desktop"></separator>
          <Tooltip title="Refresh" arrow={false}>
            <group data-width="auto" data-length="forcefit">
              <Button
                data-length="forcefit"
                primary
                //large
                icon="refresh"
                //text="New"
                onClick={() => setReload(true)}
              />
            </group>
          </Tooltip>
        </group>

        <ListView
          dataSource={dataSource}
          view={ListViewType.Block}
          keyField="id"
          data-template="300"
          //data-test=""
          listProps={{ 'data-gap': '10' }}
          itemComponent={BlockItem}
        />
      </view>
      // <>
      //   <view>
      //     <view className="table_cont" data-background="none">
      //       <group
      //         data-space="15"
      //         data-gap="20"
      //         data-border=""
      //         data-background="highlight"
      //       >
      //         <Select
      //           autoComplete=""
      //           label="Device"
      //           labelPosition="left"
      //           dataLength="320"
      //           value={currentAccountDeviceId}
      //           onChange={(e: any) => setCurrentAccountDeviceId(e)}
      //           loading={loading}
      //           allowSearch={true}
      //           options={
      //             freeAccountDevices?.map((item) => {
      //               return {
      //                 text: `${item.name!} (${item.id!})`,
      //                 value: item.id!,
      //               };
      //             }) || []
      //           }
      //         />
      //         <Button
      //           material
      //           disabled={!currentAccountDeviceId ? true : undefined}
      //           icon="add"
      //           text="Add Device to Profile"
      //           primary
      //           onClick={onAddNewDeviceToGroup}
      //         />
      //         <Button
      //           data-position="right"
      //           material
      //           icon="refresh"
      //           text="Refresh"
      //           outline
      //           onClick={() => setReload(true)}
      //         />
      //       </group>
      //       <DataTable
      //         columns={columns}
      //         bordered={true}
      //         customPagination={true}
      //         reload={reload}
      //         dataPagination={true}
      //         getPageAsync={getPage}
      //         loading={loading}
      //         rowKey={(record) => `${record.id}`}
      //         showTotal={true}
      //         size="small"
      //         totalRecords={totalRecords}
      //       />
      //     </view>
      //   </view>
      // </>
    );
  }

  const renderInternetPauseTab = () => {
    return (
      <>
        <view
          data-gap="20"
          data-space="20"
          // data-max-length="400"
          data-position="center"
          data-border="none">
          {/* <view className="table_cont" data-background="none">
            <group
              data-space="15"
              data-gap="20"
              data-border=""
              data-background="highlight"
            >
              <Checkbox
                label={hasInternet ? "Internet Enabled for device" : `Internet Disabled for device until ${moment(currentAccountDeviceGroup.blockedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
                minimal
                dataLength="auto"
                onChange={onInternetCheckboxChange}
                checked={hasInternet} />
            </group>
          </view> */}
          <group data-width="auto" data-space="15" data-justify="center">
            <Tooltip title="Internet Pause" arrow={false}>
              <group data-width="auto" data-color="white">
                <group
                  // data-background={hasInternet ? "main" : "secondary"}
                  data-background="main-background"
                  data-length="200"
                  data-height="200"
                  data-elevation="6"
                  rounded
                  data-radius="full"
                  data-border='outline'
                  data-space="5"
                  onClick={onInternetPauseClick}
                  data-text-size="xx-large"
                  data-weight="600"
                  data-color="gray"
                >
                  {hasInternet ?
                    // <icon data-position="center" data-icon-size="large">pause</icon>
                    <text data-position="center" className="timer-text" data-color="gray">
                      {formatedTime()}
                    </text>
                    : <TimerComponent remaining={internetPauseRemainingTime} duration={internetPauseTotalTime} />}
                </group>
              </group>
            </Tooltip>
          </group>
          {!hasInternet ?
            // <group data-width="auto" data-space-horizontal="15" data-justify="center" >
            //   <badge data-border="" data-outline=""><text data-wrap="wrap" data-space="5" data-weight="600">{`Internet Disabled for device until ${moment(currentAccountDeviceGroup.blockedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}</text></badge>
            // </group>
            <></>
            :
            <group data-space-horizontal="30" data-justify="center" data-direction="column" data-gap="10">
              <text>Minutes:</text>
              <Slider data-width="auto" step={5} min={0} max={59} onChange={setMinutesBeforeExpiration} />
              <text>Hours:</text>
              <Slider min={0} max={23} onChange={setHoursBeforeExpiration} />
            </group>
          }
          <group data-space-horizontal="30" data-justify="center" data-direction="column" data-gap="10">
            <Button
              material
              text={hasInternet ? "Pause" : "Unpause"}
              primary
              onClick={onInternetPauseClick}
            />
          </group>

        </view>
      </>
    );
  }

  const renderBandwidthTab = () => {
    return (
      <>
        <view>
          <view className="table_cont" data-background="none">
            <group
              data-space="15"
              data-gap="20"
              data-border=""
              data-background="highlight">
              <Checkbox
                label="Activity Logs Enabled"
                minimal
                dataLength="auto"
                onChange={onActivityLogsCheckboxChange}
                checked={activityLogsEnabled} />
            </group>
            {
              activityLogsEnabled &&
              <AccountDeviceActivityLogs isDomainBased={true} refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceGroupReference={currentAccountDeviceGroup.id} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'activityLogs')} />
            }
          </view>
        </view>
      </>
    );
  }

  const onStepSave = (acc: Account, step: string) => {
    switch (step) {
      default:
        break;
    }

    // if (onSave) {
    //   onSave(account);
    // }
  };

  const onItemClick = (record: any) => {
    let itemIndex = accountDeviceGroups.findIndex(x => x.id === record.id);
    setCurrentAccountDeviceGroup(accountDeviceGroups[itemIndex]);
    setReload(true);
  }

  return (
    <Popup
      adaptive
      title={modalTitle || (`${(!currentAccountDeviceGroup.id ? "New Profile" : `Profile Name: ${currentAccountDeviceGroup.name}`)}`) || `Account: ${account.accountName} - Kibosh Device: ${accountKiboshDevice.kiboshDeviceName} - ${(!currentAccountDeviceGroup.id ? "New Profile" : `Profile Name: ${currentAccountDeviceGroup.name}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="x-large"
    >
      {loading &&
        <Loader />
      }
      {
        !!accountDeviceGroups.length &&
        <>
          <group
            data-align="center"
            data-direction="column"
            data-wrap='no'
            data-background="main"
            data-position="absolute"
            data-top="0"
            data-height='fit'
          >
            <SvgDoodle />
          </group>
          <group data-name="profiles_slide" data-wrap="no" data-scroll-type="snap" data-type="snap" data-space-vertical="20">
            {
              accountDeviceGroups.map((item: any, index: any) => (
                <group
                  key={`${item.id}-${index}`}
                  data-position="center"
                  data-direction="column"
                  data-align="center"
                  data-gap="10"
                  data-length="90"
                  data-cursor="pointer"
                  className={item.id === currentAccountDeviceGroup.id ? "item selected" : "item"}
                  onClick={() => onItemClick(item)}>
                  <group data-wrap="no" >
                    <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="120" data-radius="full" data-background="main" data-color="white" data-border='2'>
                      <picture>
                        {item.icon ? <img src={item.icon} alt="" /> : <text data-text-size="xx-large" data-weight="700">{getStringFirstLetters(item.name!)}</text>}
                      </picture>
                    </group>
                    {
                      !!item.isBlocked &&
                      <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="30" data-height="30" data-radius="full" data-background="main-dark" data-color="white" data-border='2' data-align-self="end" data-margin-left="-30">
                        <picture>
                          <icon data-position="center">pause</icon>
                        </picture>
                      </group>
                    }
                  </group>
                  <text data-color="white" data-weight="600" data-ellipsis="">{item.name}</text>
                </group>
              ))
            }
          </group>
        </>
      }

        {
          !!accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length ?
            <TabStrip
              skipSecondaryTabs={false}
              selectedIndex={currentTab}
              onChange={setCurrentTab}
              id="device-tab"
              showTabs={showTabs}>
              <Tab title={showGroupEditSection ? 'Details' : 'Devices'}>
                {showGroupEditSection ? renderDetailsTab() : renderDevicesTab()}
              </Tab>
              <Tab title='App’s' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} showFilters={true} showSchedules={true} showCustomTableAsSwitchable={true} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'applications')} />
                }
              </Tab>
              <Tab title='VPN' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  // <AccountKiboshDeviceMenuModal
                  //   open={!!account.accountId}
                  //   closeOnSave={true}
                  //   account={account!}
                  //   accountKiboshDevice={accountKiboshDevice}
                  //   deviceReference={accountKiboshDevice.associatedRouterClientId}
                  // />
                  <AccountKiboshDeviceModal
                    modalTitle={
                      `${!accountKiboshDevice.accountKiboshDeviceId
                        ? "New Kibosh Device"
                        : `Kibosh Device Name: ${accountKiboshDevice.kiboshDeviceName}`
                      }`
                    }
                    open={!!account.accountId}
                    isModal={false}
                    closeOnSave={true}
                    account={account!}
                    accountKiboshDevice={{ ...accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)[0], kiboshDeviceReference: accountKiboshDevice?.kiboshDeviceReference }}
                    deviceReference={accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)[0].associatedRouterClientId}
                    onSave={(acc) => onStepSave(acc, 'vpns')}
                  />
                }
              </Tab>
              <Tab title='Website’s' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} showFilters={true} showSchedules={true} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'blockedSites')} />
                }
              </Tab>
              <Tab title='Internet Schedules' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  <AccountInternetSchedules refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'internetSchedules')} />
                }
              </Tab>
              <Tab title='Pause' disabled={showGroupEditSection}>
                {renderInternetPauseTab()}
              </Tab>
              <Tab title='History' disabled={showGroupEditSection}>
                {renderBandwidthTab()}
              </Tab>
              <Tab title='Bandwidth' disabled={showGroupEditSection}>
                <AccountDeviceBandwidthLogs refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceGroupReference={currentAccountDeviceGroup.id} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'bandwidthLogs')} />
              </Tab>
            </TabStrip>
            :
            <TabStrip
              skipSecondaryTabs={false}
              selectedIndex={currentTab}
              onChange={setCurrentTab}
              id="device-tab"
              showTabs={showTabs}>
              <Tab title={showGroupEditSection ? 'Details' : 'Devices'}>
                {showGroupEditSection ? renderDetailsTab() : renderDevicesTab()}
              </Tab>
              <Tab title='App’s' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} showFilters={true} showSchedules={true} showCustomTableAsSwitchable={true} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'applications')} />
                }
              </Tab>
              <Tab title='Website’s' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} showFilters={true} showSchedules={true} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'blockedSites')} />
                }
              </Tab>
              <Tab title='Internet Schedules' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
                {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
                  <AccountInternetSchedules refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'internetSchedules')} />
                }
              </Tab>
              <Tab title='Pause' disabled={showGroupEditSection}>
                {renderInternetPauseTab()}
              </Tab>
              <Tab title='History' disabled={showGroupEditSection}>
                {renderBandwidthTab()}
              </Tab>
              <Tab title='Bandwidth' disabled={showGroupEditSection}>
                <AccountDeviceBandwidthLogs refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceGroupReference={currentAccountDeviceGroup.id} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'bandwidthLogs')} />
              </Tab>
            </TabStrip>
        }

      {/* {isBlockingConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText={blockingData?.blocked ? "Unblock" : "Block"}
          positiveCallback={handleBlocking}
          negativeCallback={() => {
            setBlockingConfirmationPopupOpen(false);
          }}
        />
      )} */}
      {isHasInternetConfirmationPopupOpen && (
        // <ConfirmationPopup
        //   showButton={false}
        //   positiveButtonText={internetData?.blocked ? "Disable Internet" : "Enabled Internet"}
        //   positiveCallback={handleInternetCheckboxChange}
        //   negativeCallback={() => {
        //     setHasInternetConfirmationPopupOpen(false);
        //   }}
        // />
        <AccountDeviceBlockingModal
          open={isHasInternetConfirmationPopupOpen}
          accountDeviceId={currentAccountDeviceGroup.id!}
          accountDeviceName={currentAccountDeviceGroup.name!}
          blockingData={internetData!}
          closeOnSave={true}
          onClose={() => setHasInternetConfirmationPopupOpen(false)}
          onSave={onAccountDeviceBlockingSave}
        />
      )}
      {!!showAccountDeviceMenuModal && (
        <AccountDeviceMenuModal
          open={showAccountDeviceMenuModal}
          account={account!}
          accountDevice={currentAccountDevice}
          accountKiboshDevice={accountKiboshDevice}
          showGroupDetails={false}
          onClose={() => setShowAccountDeviceMenuModal(false)}
          onSave={() => onAccountDeviceSave()}
        />
      )}

      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Remove"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </Popup>
  );
}
