// 3rd-party modules
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// project modules
import Loader from "../../components/shared/loader";
import { apiCall } from "../../helpers/apiHelper";
import { RootState } from "../../stores";
import { CONSTANTS } from "../../helpers/defines";

// apis
import * as DashApi from "../../apis/dashApi";

// modals
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { AccountKiboshDeviceStatus } from "../../models/accountKiboshDeviceStatus";
import { AccountDevice, AccountDeviceBandwidthLog } from "../../models/accountDevice";

export default function HomePage() {
  const kiboshDevice = useSelector((state: RootState) => state.user?.kiboshDevice) || new AccountKiboshDevice();

  const [currentKiboshDeviceStatus, setCurrentKiboshDeviceStatus] = useState<AccountKiboshDeviceStatus>(new AccountKiboshDeviceStatus());
  const [connectedDevicesCount, setConnectedDevicesCount] = useState<number>(0);
  const [vpnsCount, setVpnsCount] = useState<number>(0);
  const [malwaresCount, setMalwaresCount] = useState<number>(0);
  const [downloadSpeed, setDownloadSpeed] = useState<number>(0);
  const [uploadSpeed, setUploadSpeed] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    return () => { abortController.abort(); }
  }, []);

  useEffect(() => {
    getDataAsync();
  }, [kiboshDevice]);

  const getDataAsync = async () => {
    if (!kiboshDevice.kiboshDeviceReference) {
      return;
    }
    setLoading(true);
    Promise.all([
      getAccountKiboshDeviceStatus(abortController.signal),
      getAccountDevices(abortController.signal),
      getDeviceLatestBandwidths(abortController.signal)
    ]).then(
      (results: any) => {
        const kiboshDeviceStatus = results[0];
        const devices = results[1];
        const bandwidth = results[2];

        if (devices && devices.length > 0) {
          setConnectedDevicesCount(devices.filter((x: AccountDevice) => x.pingMs && x.pingMs > 0).length);
          setVpnsCount(devices.filter((x: AccountDevice) => x.isVpn).length);
          setMalwaresCount(devices.map((x: AccountDevice) => x.detectedMalware ? x.detectedMalware.length : 0).reduce((a: any, b: any) => a + b))
        }

        setCurrentKiboshDeviceStatus(kiboshDeviceStatus);
        setDownloadSpeed(bandwidth.length ? bandwidth[0].intervalDownloadBandwidth : 0)
        setUploadSpeed(bandwidth.length ? bandwidth[0].intervalUploadBandwidth : 0)
      }
    ).finally(() => {
      setLoading(false);
    });
  }

  const getAccountDevices = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getClientDevices(kiboshDevice?.kiboshDeviceReference!, abortSignal));
    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountKiboshDeviceStatus = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getKiboshDeviceStatus(kiboshDevice?.kiboshDeviceReference!, abortSignal));
    return response.success ? AccountKiboshDeviceStatus.toClass(response.data?.value) : null;
  };

  const getDeviceLatestBandwidths = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getDeviceLatestBandwidths(kiboshDevice?.kiboshDeviceReference!, true, abortSignal));

    return response.success ? AccountDeviceBandwidthLog.toArrayOfClass(response.data?.value || []) : [];
  };

  return (
    <>
      {(loading) && <Loader />}
      <view data-vertical="">
        <view
          data-scroll=""
          data-space="20">
          <group data-gap="10" data-direction="column">
            <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Home</text>
            <text data-wrap="wrap" data-length="400" data-line="1.5" data-light="">Your network overall status.</text>
          </group>
          <section data-space="1">
              <group data-gap="20">
                <group>
                  <space data-height="20"></space>
                  <group data-gap="10" data-direction="column">
                    {
                      currentKiboshDeviceStatus?.kiboshDevice?.connectionStatus === "connected" && currentKiboshDeviceStatus?.kiboshDevice?.remoteDnsStatus === "connected" ?
                      <text data-weight="600" data-wrap="wrap">Your Internet is <text data-color="main-dark">online</text> and <text data-color="main-dark">protected</text></text> :
                      currentKiboshDeviceStatus?.kiboshDevice?.connectionStatus === "connected" ?
                      <text data-weight="600" data-wrap="wrap">Your Internet is <text data-color="main-dark">online</text></text> :
                      <text data-weight="600" data-wrap="wrap">Your Internet is offline</text>
                    }
                    <text data-weight="600" data-wrap="wrap">
                      Last Checkin:
                      <text data-weight="700" data-wrap="wrap" data-color="main-dark">
                        {currentKiboshDeviceStatus?.kiboshDevice?.lastDeviceCheckin ? moment(currentKiboshDeviceStatus?.kiboshDevice?.lastDeviceCheckin).format(CONSTANTS.DEFAULT_DATETIME_FORMAT) : "-"}
                      </text>
                    </text>
                    <text data-wrap="wrap" data-weight="600">
                      WAN IP:
                      <text data-weight="700" data-wrap="wrap" data-color="main-dark">
                        {currentKiboshDeviceStatus?.kiboshDevice?.currentWanIp || "-"}
                      </text>
                    </text>
                  </group>
                  <space data-height="40"></space>
                </group>
                <group data-gap="10" data-type="grid" data-grid-template="160">
                  <group data-space="20" data-direction="column" data-align="center" data-weight="600">
                    <group data-length="70" data-height="70" data-radius="50">
                      <icon data-text-size="xx-large" data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="500" data-color="main" data-elevation="5" data-background="main-color">language</icon>
                      <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background="blue" data-border="" data-top="0" data-right="0"></status>
                    </group>
                    <space data-height="10"></space>
                    <text data-weight="600">Internet</text>
                    <text>{currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs && currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs > 0 ? `${currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs.toFixed(2)} ms` : "Not Connected"}</text>
                  </group>
                  <group data-space="20" data-direction="column" data-align="center" data-weight="600">
                    <group data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="600" data-elevation="5" data-background="main-color">
                      <text data-text-size="large">{connectedDevicesCount}</text>
                      <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background="main" data-border="" data-top="0" data-right="0"></status>
                    </group>
                    <space data-height="10"></space>
                    <text data-max-length="100" data-wrap="wrap" data-text-align="center">Connected Devices</text>
                  </group>
                  <group data-space="20" data-direction="column" data-align="center" data-weight="600">
                    <group data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="600" data-elevation="5" data-background="main-color">
                      <text data-text-size="large">{vpnsCount}</text>
                      <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background="main" data-border="" data-top="0" data-right="0"></status>
                    </group>
                    <space data-height="10"></space>
                    <text>VPNs</text>
                  </group>
                  <group data-space="20" data-direction="column" data-align="center" data-weight="600">
                    <group data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="600" data-elevation="5" data-background="main-color">
                      <text data-text-size="large">{malwaresCount}</text>
                      <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background="red" data-border="" data-top="0" data-right="0"></status>
                    </group>
                    <space data-height="10"></space>
                    <text data-max-length="100" data-wrap="wrap" data-text-align="center" >Malwere</text>
                    <text data-max-length="150" data-wrap="wrap" data-text-align="center" data-color="main">Definition: Current</text>
                  </group>
                </group>
                <separator data-horizontal=""></separator>

                <group>
                  <group data-type="column" data-column-size="160">
                    <group data-space="20" data-align="center" >
                      <group data-direction="column" data-length='160'>
                        <text data-text-size="xx-large" data-text-align="center" data-light="">{((downloadSpeed/1024)/1024).toFixed(2)}</text>
                        <group>
                          <icon data-icon-size="48" data-color="main-dark">arrow_downward</icon>
                          <group data-direction="column" data-fit="1" data-justify="center">
                            <text data-weight="600" data-color="main-dark">Download</text>
                            <text data-light="">Mbps</text>
                          </group>
                        </group>
                      </group>
                    </group>
                    <group data-space="20" data-align="center" >
                      <group data-direction="column" data-length='160'>
                        <text data-text-size="xx-large" data-text-align="center" data-light="">{((uploadSpeed/1024)/1024).toFixed(2)}</text>
                        <group>
                          <icon data-icon-size="48" data-color="main-dark">arrow_upward</icon>
                          <group data-direction="column" data-fit="1" data-justify="center">
                            <text data-weight="600" data-color="main-dark">Upload</text>
                            <text data-light="">Mbps</text>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>

          </section>
        </view>
      </view>
    </>
  );
}
